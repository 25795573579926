export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      interestLateFeeData: [
        {
          sub_type: null,
          integrated_tax: null,
          central_tax: null,
          state_tax: null,
          cess: null
        }
      ],
      interestLateFeeFields: [
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class: 'stickyColumn col-sm-1'
        // },
        {
          key: 'sub_type',
          label: 'Description',
          class: 'text-left'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      // selectedRow: [],
      // selectAllCheckBox: false
    };
  },
  mounted() {
    // this.taxOutward();
    this.getGstrThreeBTabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editGstrThreeBTabData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    // taxOutward() {
    //   if (!this.searchValue.filed_flag) {
    //     this.interestLateFeeFields.splice(0, 1);
    //   }
    // },
    getGstrThreeBTabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.interestLateFeeData = response.data.data.detail;
            this.initialInterestLateFeeData = JSON.parse(
              JSON.stringify(response.data.data.detail)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.interestLateFeeData = this.interestLateFeeData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = this.interestLateFeeData;
      } else {
        this.interestLateFeeData = this.interestLateFeeData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      }
    },
    selectBoxChecked(flag, index, item, subTypeName) {
      this.interestLateFeeData[index].selectBox = flag;
      if (flag) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.request_id !== subTypeName
        );
      }
    },
    editGstrThreeBTabData() {
      // const filterData = this.interestLateFeeData.filter(
      //   data => data.selectBox
      // );
      // if (filterData.length) {
      //   const data = filterData.map(ele => {
      //     return {
      //       le_id: this.searchValue.le_id,
      //       gst_no: this.searchValue.gst_number,
      //       period_name: this.searchValue.period,
      //       correct_taxable_value: ele.taxable_value.replace(/,/g, ''),
      //       correct_igst_amount: ele.integrated_tax.replace(/,/g, ''),
      //       correct_cgst_amount: ele.central_tax.replace(/,/g, ''),
      //       correct_sgst_amount: ele.state_tax.replace(/,/g, ''),
      //       correct_cess_amount: ele.cess.replace(/,/g, ''),
      //       sub_type: ele.sub_type,
      //       sub_sub_type: ele.sub_sub_type ? ele.sub_sub_type : null,
      //       type: this.tabDetail.type
      //     };
      //   });

      const data = [];
      for (let i = 0; i < this.interestLateFeeData.length; i++) {
        const itemA = this.initialInterestLateFeeData[i];
        const itemB = this.interestLateFeeData[i];
        if (
          itemA.taxable_value != itemB.taxable_value ||
          itemA.central_tax != itemB.central_tax ||
          itemA.state_tax != itemB.state_tax ||
          itemA.cess != itemB.cess ||
          itemA.integrated_tax != itemB.integrated_tax
        ) {
          data.push({
            le_id: this.searchValue.le_id,
            gst_no: this.searchValue.gst_number,
            period_name: this.searchValue.period,
            correct_taxable_value: itemB.taxable_value.replace(/,/g, ''),
            correct_igst_amount: itemB.integrated_tax.replace(/,/g, ''),
            correct_cgst_amount: itemB.central_tax.replace(/,/g, ''),
            correct_sgst_amount: itemB.state_tax.replace(/,/g, ''),
            correct_cess_amount: itemB.cess.replace(/,/g, ''),
            sub_type: this.interestLateFeeData[i].sub_type,
            sub_sub_type: itemB.sub_sub_type ? itemB.sub_sub_type : '',
            type: this.tabDetail.type
          });
        }
      }
      const payload = {
        page: data
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.$emit('getGstrThreeBSummaryTabData');
            this.getGstrThreeBTabsData();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
      // }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
