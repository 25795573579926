export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      exemptData: [
        {
          sub_sub_type: null,
          inter_state_supplies: null,
          intra_state_supplies: null
        }
      ],
      exemptFields: [
        {
          key: 'sub_sub_type',
          label: 'Nature of Supplies',
          class: 'text-left'
        },
        {
          key: 'inter_state_supplies',
          label: 'Inter-State Supplies (₹)',
          class: 'text-right'
        },
        {
          key: 'intra_state_supplies',
          label: 'Intra-State Supplies (₹)',
          class: 'text-right'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialExtemtData: [],
      subType:null
    };
  },
  mounted() {
    this.getGstrThreeBTabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.updateExemptData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstrThreeBTabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.exemptData = response.data.data.detail;
            this.subType = response.data.data.sub_type
            this.initialExtemtData = JSON.parse(
              JSON.stringify(response.data.data.detail)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateExemptData() {
      const data = [];
      for (let i = 0; i < this.exemptData.length; i++) {
        const itemA = this.initialExtemtData[i];
        const itemB = this.exemptData[i];
        if (
          itemA.inter_state_supplies != itemB.inter_state_supplies ||
          itemA.intra_state_supplies != itemB.intra_state_supplies
        ) {
          data.push({
            le_id: this.searchValue.le_id,
            gst_no: this.searchValue.gst_number,
            period_name: this.searchValue.period,
            correct_inter_state_supplies: itemB.inter_state_supplies.replace(/,/g, ''),
            correct_intra_state_supplies: itemB.intra_state_supplies.replace(/,/g, ''),
            sub_type: this.subType,
            sub_sub_type: itemB.sub_sub_type,
            type: this.tabDetail.type
          });
        }
      }
      const payload = {
        page: data
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.$emit('getGstrThreeBSummaryTabData');
            this.getGstrThreeBTabsData();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
