export default {
  name: 'supplyDetails',
  props: ['searchValue', 'rowData'],
  components: {},
  data() {
    return {
      supplieDtlsData: [],
      supplieDtlsFields: [
        {
          key: 'pos',
          label: 'Place of Supply (State/UT)',
          class: 'text-left'
        },
        {
          key: 'Taxable_Value',
          label: 'Total Taxable Value (₹)',
          class: 'text-right'
        },
        {
          key: 'Integrated_Tax',
          label: 'Amount of Integrated Tax (₹)',
          class: 'text-right column-width-fix'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialSupplyDtlsData: []
    };
  },
  mounted() {
    this.getSupplyDtlsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.updateSupplyDtlsData();
          this.editMode = false;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getSupplyDtlsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        sub_type: this.rowData.sub_type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getInterStateSupplyDetlsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.supplieDtlsData = response.data.data;
            this.initialSupplyDtlsData = JSON.parse(
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateSupplyDtlsData() {
      const data = [];
      for (let i = 0; i < this.supplieDtlsData.length; i++) {
        const itemA = this.initialSupplyDtlsData[i];
        const itemB = this.supplieDtlsData[i];
        if (
          itemA.Taxable_Value != itemB.Taxable_Value ||
          itemA.Integrated_Tax != itemB.Integrated_Tax
        ) {
          data.push({
            le_id: this.searchValue.le_id,
            gst_no: this.searchValue.gst_number,
            period_name: this.searchValue.period,
            correct_taxable_value: itemB.Taxable_Value,
            correct_igst_amount: itemB.Integrated_Tax,
            sub_type: this.rowData.sub_type,
            type: this.rowData.type,
            pos: itemB.state_code
          });
        }
      }
      const payload = {
        page: data
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.$emit('getGstrThreeBTabsData');
            this.getSupplyDtlsData();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
