var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"pl-3",attrs:{"md":"12"}},[_c('div',{staticClass:"bg-white billing-table b-table-sticky-header-fix table-responsive"},[_c('table',{staticClass:"table b-table table-striped border",staticStyle:{"width":"100%"}},[_c('tr',[_c('th',{staticStyle:{"width":"30%"}},[_vm._v("Details")]),_c('th',[_vm._v("Integrated Tax (₹)")]),_c('th',[_vm._v("Central Tax (₹)")]),_c('th',[_vm._v("State/UT Tax (₹)")]),_c('th',[_vm._v("CESS(₹)")])]),_vm._l((_vm.eligibleItcData),function(item,index){return _c('tbody',{key:index},[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('strong',[_vm._v(_vm._s(item.sub_type_name))])])]),_vm._l((item.itms),function(childItem,childIndex){return _c('tr',{key:childIndex},[_c('td',[_vm._v(" "+_vm._s(childItem.sub_sub_type_name)+" ")]),_c('td',[_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"input-box-style",class:_vm.searchValue.filed_flag &&
                    childItem.sub_sub_type_name === 'Net ITC Available'
                      ? 'bg-color-change'
                      : '',attrs:{"title":childItem.integrated_tax,"disabled":!_vm.editMode ||
                      childItem.sub_sub_type_name === 'Net ITC Available'},model:{value:(childItem.integrated_tax),callback:function ($$v) {_vm.$set(childItem, "integrated_tax", $$v)},expression:"childItem.integrated_tax"}})],1),_c('td',[_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"input-box-style",class:_vm.searchValue.filed_flag &&
                    (childItem.sub_sub_type_name === 'Import of Goods' ||
                      childItem.sub_sub_type_name === 'Import of Services' ||
                      childItem.sub_sub_type_name === 'Net ITC Available')
                      ? 'bg-color-change'
                      : '',attrs:{"title":childItem.central_tax,"disabled":!_vm.editMode ||
                      childItem.sub_sub_type_name === 'Import of Goods' ||
                      childItem.sub_sub_type_name === 'Import of Services' ||
                      childItem.sub_sub_type_name === 'Net ITC Available'},model:{value:(childItem.central_tax),callback:function ($$v) {_vm.$set(childItem, "central_tax", $$v)},expression:"childItem.central_tax"}})],1),_c('td',[_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"input-box-style",class:_vm.searchValue.filed_flag &&
                    (childItem.sub_sub_type_name === 'Import of Goods' ||
                      childItem.sub_sub_type_name === 'Import of Services' ||
                      childItem.sub_sub_type_name === 'Net ITC Available')
                      ? 'bg-color-change'
                      : '',attrs:{"title":childItem.state_tax,"disabled":!_vm.editMode ||
                      childItem.sub_sub_type_name === 'Import of Goods' ||
                      childItem.sub_sub_type_name === 'Import of Services' ||
                      childItem.sub_sub_type_name === 'Net ITC Available'},model:{value:(childItem.state_tax),callback:function ($$v) {_vm.$set(childItem, "state_tax", $$v)},expression:"childItem.state_tax"}})],1),_c('td',[_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"input-box-style",class:_vm.searchValue.filed_flag &&
                    childItem.sub_sub_type_name === 'Net ITC Available'
                      ? 'bg-color-change'
                      : '',attrs:{"title":childItem.cess,"disabled":!_vm.editMode ||
                      childItem.sub_sub_type_name === 'Net ITC Available'},model:{value:(childItem.cess),callback:function ($$v) {_vm.$set(childItem, "cess", $$v)},expression:"childItem.cess"}})],1)])})],2)})],2)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }