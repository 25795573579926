export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      paymentTaxData: [
        {
          sub_type: null,
          integrated_tax: null,
          central_tax: null,
          state_tax: null,
          cess: null,
          total: null
        }
      ],
      paymentTaxFields: [
        {
          key: 'sub_type',
          label: 'Description',
          class: 'text-left'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Total (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false
    };
  },
  mounted() {
    this.getGstrThreeBTabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.getGstrThreeBTabsData();
        }
        if (actionName === 'update') {
          this.editMode = true;  
        }
      }
    });
  },
  methods: {
    getGstrThreeBTabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentTaxData = response.data.data.detail;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
