import supplyDetails from './supplyDetails';
export default {
  props: ['tabDetail', 'searchValue'],
  components: { supplyDetails },
  data() {
    return {
      interStateSupplieData: [
        {
          sub_type_name: null,
          taxable_value: null,
          integrated_tax: null
        }
      ],
      interStateSupplieFields: [
        {
          key: 'sub_type_name',
          label: '',
          class: 'text-left'
        },
        {
          key: 'taxable_value',
          label: 'Total Taxable Value (₹)',
          class: 'text-center'
        },

        {
          key: 'integrated_tax',
          label: 'Amount of Integrated Tax (₹)',
          class: 'text-center column-width-fix'
        }
      ],
      unsubscribe: null,
      // editMode: false,
      showOpenInterStateModal: false,
      rowSelectedData: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      }
    };
  },
  mounted() {
    this.getGstrThreeBTabsData();
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'save' && this.editMode) {
    //       this.getGstrThreeBTabsData();
    //     }
    //     if (actionName === 'update') {
    //       this.editMode = true;
    //     }
    //   }
    // });
  },
  methods: {
    getGstrThreeBTabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.interStateSupplieData = response.data.data.detail;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      if (this.searchValue.filed_flag) {
        this.rowSelectedData = item;
        this.rowSelectedData.type = this.tabDetail.type
        this.showHideInterStateModal(true);
      }
    },
    showHideInterStateModal(flag) {
      this.showOpenInterStateModal = flag;
    }
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // }
};
