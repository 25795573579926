export default {
  props: ['itemSummary'],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getKeyAndValue() {
      let rawHtml = '';
      let index = 1;
      for (const key in this.itemSummary) {
        const className =
          index % 2 === 0 ? 'text-align-right' : 'text-align-left';
        rawHtml += ` <div md="6" class="mt-0 col-md-6 ${className} borderBottom">
        <label for="filter"><strong>${key}</strong> </label>
        <label for="filter" class="display-block">${this.itemSummary[key]}</label>
      </div>
      <div></div>`;
        index++;
      }
      return rawHtml;
    }
  }
};
