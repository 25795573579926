export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      supplieNotifiedData: [
        {
          sub_type_name: null,
          taxable_value: null,
          integrated_tax: null,
          state_tax: null,
          central_tax: null,
          cess: null
        }
      ],
      supplieNotifiedFields: [
        {
          key: 'sub_type_name',
          label: 'Description',
          class: 'text-left des-col-fix'
        },
        {
          key: 'taxable_value',
          label: 'Total Taxable Value (₹)',
          class: 'text-center'
        },

        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      subTypeName: null,
      initialSpplieNotifiedData: []
    };
  },
  mounted() {
    this.getGstrThreeBTabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.editGstrThreeBTabData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstrThreeBTabsData() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.supplieNotifiedData = response.data.data.detail;
            this.initialSpplieNotifiedData = JSON.parse(
              JSON.stringify(response.data.data.detail)
            );
            this.subTypeName = this.supplieNotifiedData[1].sub_type_name;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.supplieNotifiedData = this.supplieNotifiedData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = this.supplieNotifiedData;
      } else {
        this.supplieNotifiedData = this.supplieNotifiedData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      }
    },
    selectBoxChecked(flag, index, item, subTypeName) {
      this.supplieNotifiedData[index].selectBox = flag;
      if (flag) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.request_id !== subTypeName
        );
      }
    },
    editGstrThreeBTabData() {
      const data = [];
      for (let i = 0; i < this.supplieNotifiedData.length; i++) {
        const itemA = this.initialSpplieNotifiedData[i];
        const itemB = this.supplieNotifiedData[i];
        if (
          itemA.taxable_value != itemB.taxable_value ||
          itemA.central_tax != itemB.central_tax ||
          itemA.state_tax != itemB.state_tax ||
          itemA.cess != itemB.cess ||
          itemA.integrated_tax != itemB.integrated_tax
        ) {
          data.push({
            le_id: this.searchValue.le_id,
            gst_no: this.searchValue.gst_number,
            period_name: this.searchValue.period,
            correct_taxable_value: itemB.taxable_value.replace(/,/g, ''),
            correct_igst_amount: itemB.integrated_tax.replace(/,/g, ''),
            correct_cgst_amount: itemB.central_tax.replace(/,/g, ''),
            correct_sgst_amount: itemB.state_tax.replace(/,/g, ''),
            correct_cess_amount: itemB.cess.replace(/,/g, ''),
            sub_type: this.supplieNotifiedData[i].sub_type,
            sub_sub_type: itemB.sub_sub_type ? itemB.sub_sub_type : '',
            type: this.tabDetail.type
          });
        }
      }
      const payload = {
        page: data
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.$emit('getGstrThreeBSummaryTabData');
            this.getGstrThreeBTabsData();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
