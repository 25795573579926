var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",staticStyle:{"max-height":"calc(90vh - 250px) !important"},attrs:{"striped":"","hover":"","outlined":"","items":_vm.taxOutwardData,"fields":_vm.taxOutwardFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(taxable_value)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",attrs:{"title":data.item.taxable_value,"disabled":!_vm.editMode},model:{value:(data.item.taxable_value),callback:function ($$v) {_vm.$set(data.item, "taxable_value", $$v)},expression:"data.item.taxable_value"}})]}},{key:"cell(integrated_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                (data.item.sub_type_name ===
                  'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.integrated_tax,"disabled":!_vm.editMode ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies'},model:{value:(data.item.integrated_tax),callback:function ($$v) {_vm.$set(data.item, "integrated_tax", $$v)},expression:"data.item.integrated_tax"}})]}},{key:"cell(central_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                (data.item.sub_type_name ===
                  'Outward taxable  supplies  (zero rated )' ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.central_tax,"disabled":!_vm.editMode ||
                  data.item.sub_type_name ===
                    'Outward taxable  supplies  (zero rated )' ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies'},model:{value:(data.item.central_tax),callback:function ($$v) {_vm.$set(data.item, "central_tax", $$v)},expression:"data.item.central_tax"}})]}},{key:"cell(state_tax)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:_vm.searchValue.filed_flag &&
                (data.item.sub_type_name ===
                  'Outward taxable  supplies  (zero rated )' ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies')
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.state_tax,"disabled":!_vm.editMode ||
                  data.item.sub_type_name ===
                    'Outward taxable  supplies  (zero rated )' ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies'},model:{value:(data.item.state_tax),callback:function ($$v) {_vm.$set(data.item, "state_tax", $$v)},expression:"data.item.state_tax"}})]}},{key:"cell(cess)",fn:function(data){return [_c('b-form-input',{directives:[{name:"numericOnlyPosNeg",rawName:"v-numericOnlyPosNeg"}],staticClass:"text-right",class:(_vm.searchValue.filed_flag &&
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)') ||
                data.item.sub_type_name === 'Non-GST outward supplies'
                  ? 'bg-color-change'
                  : '',attrs:{"title":data.item.cess,"disabled":!_vm.editMode ||
                  data.item.sub_type_name ===
                    'Other outward supplies (Nil rated, exempted)' ||
                  data.item.sub_type_name === 'Non-GST outward supplies'},model:{value:(data.item.cess),callback:function ($$v) {_vm.$set(data.item, "cess", $$v)},expression:"data.item.cess"}})]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }