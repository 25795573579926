export default {
  props: ['tabDetail', 'searchValue'],
  components: {},
  data() {
    return {
      eligibleItcData: [
      ],
      eligibleItcFields: [
        {
          key: 'sub_sub_type_name',
          label: 'Details',
          class: 'text-left'
        },
        {
          key: 'integrated_tax',
          label: 'Integrated Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'central_tax',
          label: 'Central Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'state_tax',
          label: 'State/UT Tax (₹)',
          class: 'text-center'
        },
        {
          key: 'cess',
          label: 'CESS (₹)',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      editMode: false,
      initialEligibleItcData: []
    };
  },
  mounted() {
    this.getGstrThreeBTabsData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.updateEligibleItcData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getGstrThreeBTabsData() {
      this.eligibleItcData = [];
      this.initialEligibleItcData = [];
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period,
        type: this.tabDetail.type
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getGstrThreeBTabsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let result = response.data.data.detail;
            result[2].itms[0].sub_sub_type_name = 'Net ITC Available';
            this.eligibleItcData.push(
              result[0],
              result[3],
              result[2],
              result[1]
            );
            this.initialEligibleItcData = JSON.parse(
              JSON.stringify(this.eligibleItcData)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateEligibleItcData() {
      let data = [];
      for (let i = 0; i < this.eligibleItcData.length; i++) {
        for (let j = 0; j < this.eligibleItcData[i].itms.length; j++) {
          const itemA = this.initialEligibleItcData[i].itms[j];
          const itemB = this.eligibleItcData[i].itms[j];
          if (
            itemA.taxable_value != itemB.taxable_value ||
            itemA.central_tax != itemB.central_tax ||
            itemA.state_tax != itemB.state_tax ||
            itemA.cess != itemB.cess ||
            itemA.integrated_tax != itemB.integrated_tax
          ) {
            data.push({
              le_id: this.searchValue.le_id,
              gst_no: this.searchValue.gst_number,
              period_name: this.searchValue.period,
              correct_taxable_value: itemB.taxable_value.replace(/,/g, ''),
              correct_igst_amount: itemB.integrated_tax.replace(/,/g, ''),
              correct_cgst_amount: itemB.central_tax.replace(/,/g, ''),
              correct_sgst_amount: itemB.state_tax.replace(/,/g, ''),
              correct_cess_amount: itemB.cess.replace(/,/g, ''),
              sub_type: this.eligibleItcData[i].sub_type,
              sub_sub_type: itemB.sub_sub_type,
              type: this.tabDetail.type
            });
          }
        }
      }
      const payload = {
        page: data
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/addEditGstrThreeBTabsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.$emit('getGstrThreeBSummaryTabData');
            this.getGstrThreeBTabsData();
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
